import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {providers} from "./configs/ProviderInjection";

import "./assets/scss/style.scss";
import "./assets/scss/colors.scss";

const getAppWithContextProviders = () => {

    let result = <App/>
    providers.forEach(Provider => result = <Provider>{result}</Provider>);

    return result;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    getAppWithContextProviders()
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
