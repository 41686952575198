import React, {useContext, useEffect} from 'react';
import {Dropdown, Flex, Layout} from 'antd';
import ImageView from "../../common/ImageView";
import {AuthContext} from "../../../contexts/AuthContextProvider";
import {SettingContext} from "../../../contexts/SettingContextProvider";

import "./nav_header.scss";

const {Header} = Layout;

const NavHeader = () => {

    const {logout, isLogin, getProfile, profile} = useContext(AuthContext);
    const {getSetting} = useContext(SettingContext);

    useEffect(() => {
        if (isLogin) {
            getProfile();
            getSetting();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const menuItems = [
        {
            key: 'logout',
            label: (
                <div onClick={logout}>
                    Logout
                </div>
            ),
            children: null,
            icon: null
        },
    ]

    return (
        <Header className="nav-header">

            {/*<p className="greeting-title">Good Morning 😎</p>*/}

            <Flex align="center" gap={18}>
                <div className="image-wrapper">
                    <ImageView
                        url="/assets/images/profile.svg"
                        style={{width: "20px", height: "20px"}}
                    />
                </div>

                <div className="user-detail-wrapper">
                    <p className="user-type-txt">
                        {profile?.fullName}
                    </p>
                </div>

                <Dropdown
                    className="drop-down"
                    menu={{items: menuItems}}
                    trigger={['click']}
                >
                    <ImageView
                        url="/assets/images/arrow_down.svg"
                    />
                </Dropdown>

            </Flex>

        </Header>
    );
}

export default NavHeader;
