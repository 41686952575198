const API_VERSION = "api/1.0.0"

const {
    REACT_APP_BASE_URL,
    REACT_APP_TELEGRAM_BASE_URL,
    REACT_APP_WORKFLOW_BASE_URL
} = process.env;

const BASE_URL = `${REACT_APP_BASE_URL}/${API_VERSION}`;
const SECURED_URL = `${BASE_URL}/secured`;
const PUBLIC_URL = `${BASE_URL}`;

const TELEGRAM_URL = `${REACT_APP_TELEGRAM_BASE_URL}/api`;

const WORKFLOW_URL = `${REACT_APP_WORKFLOW_BASE_URL}`;

// file uploader
export const COMPANY_LOGO_UPLOAD_URL = `${SECURED_URL}/s3/settings/company-logo/update`;
export const RETRIEVE_IMAGE_URL = `${PUBLIC_URL}/s3/get-object`;

//auth
export const USER_SIGN_IN_URL = `${PUBLIC_URL}/login/request`;
export const OTP_VERIFY_URL = `${PUBLIC_URL}/verify-otp`;
export const FORGOT_PASSWORD_URL = `${PUBLIC_URL}/forget/password/request`;
export const RESEND_OTP_URL = `${PUBLIC_URL}/resend/otp`;

// chatbot
export const CREATE_CHATBOT_URL = `${SECURED_URL}/chatbots/create`;
export const GET_ALL_CHATBOT_URL = `${SECURED_URL}/chatbots`;
export const GET_ALL_CHATBOT_FOR_PROD_URL = `${SECURED_URL}/chatbots/for-prod`;
export const GET_CHATBOT_BY_ID_URL = `${SECURED_URL}/chatbots/id`;
export const UPDATE_CHATBOT_URL = `${SECURED_URL}/chatbots/update`;
export const GET_LANGUAGES_BY_CHATBOT_ID_URL = `${SECURED_URL}/chatbots/languages`;

// uat file
export const CREATE_UAT_FILE_URL = `${SECURED_URL}/uats/create`;
export const GET_ALL_UAT_FILE_URL = `${SECURED_URL}/uats`;
export const GET_ALL_UAT_VERSION_URL = `${SECURED_URL}/uats/as-list`;
export const GET_UAT_FILE_BY_ID_URL = `${SECURED_URL}/uats/id`;
export const UPDATE_UAT_FILE_URL = `${SECURED_URL}/uats/update`;
export const DOWNLOAD_UAT_FILE_URL = `${SECURED_URL}/uat/files/download/as-zip`;

// uat performance
// export const GET_CHATBOT_PERFORMANCE = `${SECURED_URL}/uats/get-performance`;
export const GET_CHATBOT_PERFORMANCE_URL = "https://ep-performance.defai.chat/evaluate_chatbot/";

// production file
export const CREATE_PRODUCTION_FILE_URL = `${SECURED_URL}/productions/create`;
export const GET_ALL_PRODUCTION_FILE_URL = `${SECURED_URL}/productions`;
export const GET_ALL_PRODUCTION_HISTORY_URL = `${SECURED_URL}/productions/histories`;
export const UPDATE_PRODUCTION_FILE_URL = `${SECURED_URL}/productions/update`;
export const DISABLE_PRODUCTION_FILE_URL = `${SECURED_URL}/productions/disable/enable`;

//user
export const CHANGE_USER_PASSWORD = `${SECURED_URL}/users/change-password`;
export const GET_USER_PROFILE_URL = `${SECURED_URL}/users/profile`;
export const CREATE_USER_URL = `${SECURED_URL}/users/create`;
export const GET_ALL_USER_URL = `${SECURED_URL}/users`;
export const UPDATE_USER_URL = `${SECURED_URL}/users/update`;

// language
export const GET_ALL_LANGUAGE_URL = `${SECURED_URL}/languages`;
export const GET_LANGUAGES_BY_CHATBOT_URL = `${SECURED_URL}/languages/for-chatbot`;

// settings
export const GET_SETTINGS_URL = `${SECURED_URL}/settings`;
export const UPDATE_SETTINGS_URL = `${SECURED_URL}/settings/create/update`;
export const GET_SETTINGS_API_URL = `${SECURED_URL}/settings/ep-urls-keys`;

// user activity log
export const GET_USER_ACTIVITY_LOG_LIST = `${SECURED_URL}/activity-logs/by/user`;

// telegram url
export const REGISTER_TELEGRAM_URL = `${TELEGRAM_URL}/telegram/bots`;
export const GET_ALL_TELEGRAM_USER_URL = `${TELEGRAM_URL}/telegram/bots`;
export const TELEGRAM_USER_TOGGLE_URL = `${TELEGRAM_URL}/telegram/bots`;
export const DELETE_TELEGRAM_USER_URL = `${TELEGRAM_URL}/telegram/bots`;

// workflow
export const GET_MONITOR_USAGE_METRICS = `${WORKFLOW_URL}/metrics/usage`;