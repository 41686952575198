import {useLocation} from "react-router-dom";
import {ACCESS_TOKEN, DATE_FORMAT, TIME_FORMAT} from "./Constant";
import dayjs from "dayjs";
import Roles from "./Roles";
import {RETRIEVE_IMAGE_URL} from "../configs/APIUrl";
import utc from 'dayjs/plugin/utc';

export const authorizationHeader = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    return {"Authorization": `Bearer ${accessToken}`};
}

export const getErrorMessage = (error) => {
    if (error.response) {

        if (error.response.data) {
            return error.response.data.message || error.response.data.error;
        }

        return error.response.data;
    } else {
        return error.message;
    }
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const getParams = (query, data) => {
    for (const key of Object.keys(data)) {

        let param = query.get(key);

        if (Array.isArray(data[key])) {

            if (param) {
                param = param.split(",");
            } else {
                param = [];
            }
        }

        data = {...data, [key]: param || ""}
    }
    return data;
}

export const languages = [
    {name: "English"},
    {name: "Traditional Chinese"},
    {name: "Arabic"},
    {name: "Spanish"},
    {name: "Korean"},
    {name: "Japanese"},
    {name: "French"},
];

export const dateTimeFormat = date => {
    if (date) {
        return dayjs(date).format("YYYY-MM-DD, h:mm A");
    } else {
        return "N/A"
    }
}

export const fileDownloadDateFormat = date => {
    if (date) {
        return dayjs(date).format("YYYY-MM-DD_h:mm_A");
    } else {
        return "N/A"
    }
}

export const enumFormatter = value => {

    if (!value) return "";

    return value.replaceAll("_", " ");
}

export const hasRole = (loggedInUserRoles, roles) => {

    if (!roles) {
        return false;
    }

    if (roles.includes(Roles.ALL)) {
        return true;
    }

    if (!loggedInUserRoles) {
        return false;
    }

    for (const permission of roles) {

        if (loggedInUserRoles.includes(permission)) {
            return true;
        }

    }

    return false;
}

export const longToDate = date => {
    if (!date) return;
    return new Date(date);
}

export const longToDateTimeFormatter = date => {

    if (!date) return;

    dayjs.extend(utc);

    return dayjs.utc(longToDate(date)).local().format(`${DATE_FORMAT} ${TIME_FORMAT}`);

}

export const longToDateFormatter = date => {

    if (!date) return;

    dayjs.extend(utc);

    return dayjs.utc(longToDate(date)).local().format(`${DATE_FORMAT}`);

}

export const longToTimeFormatter = date => {

    if (!date) return;

    dayjs.extend(utc);

    return dayjs.utc(longToDate(date)).local().format(`${TIME_FORMAT}`);

}

export const bindUrlWithParams = (url, params) => {
    let result = url;

    Object.keys(params).forEach(key => {
        if (!params[key] || params[key].length < 1) {
            delete params[key];
        }
    });

    Object.keys(params).forEach((key, index) => {

        let param = params[key];

        if (Array.isArray(params[key])) {
            param = param.toString();
        }

        if (index === 0) {
            result += `?${key}=${param}`;
        } else {
            result += `&${key}=${param}`;
        }

    });

    return result;
}

export const getNameFromPath = (path) => {

    if (path) {
        const split = path.split("/");
        return split[split.length - 1];
    }

}

export const retrieveImageUrl = (path) => {

    if (!path) return null;

    return `${RETRIEVE_IMAGE_URL}?key=${path}`;
};
