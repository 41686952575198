import React, {createContext, useState} from 'react';
import UatFileService from "../services/UatFileService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";

export const UatFileContext = createContext("UatFileContext");

const UatFileContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [uatFileList, setUatFileList] = useState([]);

    const [uatVersionLoading, setUatVersionLoading] = useState(false);
    const [uatVersionList, setUatVersionList] = useState([]);

    const getUatFileList = async params => {
        try {

            setLoading(true);

            const res = await UatFileService.getUatFileList(params);
            setUatFileList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getUatVersionList = async params => {
        try {

            setUatVersionLoading(true);

            const res = await UatFileService.getUatVersionList(params);
            setUatVersionList(res.data);

            setUatVersionLoading(false);

        } catch (error) {

            setUatVersionLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <UatFileContext.Provider
            value={{
                loading,
                totalElements,
                uatFileList,
                getUatFileList,
                uatVersionLoading,
                uatVersionList,
                getUatVersionList,
            }}
        >
            {children}
        </UatFileContext.Provider>
    );
}

export default UatFileContextProvider;
