import ChatbotContextProvider from "../contexts/ChatbotContextProvider";
import UatFileContextProvider from "../contexts/UatFileContextProvider";
import ProductionFileContextProvider from "../contexts/ProductionFileContext";
import UserContextProvider from "../contexts/UserContextProvider";
import AuthContextProvider from "../contexts/AuthContextProvider";
import LanguageContextProvider from "../contexts/LanguageContextProvider";
import SettingContextProvider from "../contexts/SettingContextProvider";
import UserActivityLogContextProvider from "../contexts/UserActivityLogContextProvider";
import TelegramContextProvider from "../contexts/third_party/TelegramContextProvider";

export const providers = [
    AuthContextProvider,
    ChatbotContextProvider,
    UatFileContextProvider,
    ProductionFileContextProvider,
    UserContextProvider,
    LanguageContextProvider,
    SettingContextProvider,
    UserActivityLogContextProvider,
    TelegramContextProvider,
]